import React from "react"
import { graphql } from "gatsby"
import { injectIntl, Link, FormattedMessage } from "gatsby-plugin-intl"
import Layout from "../components/layout"
import SEO from "../components/seo"

class ServicePage extends React.Component {

  constructor() {
    super();

    this.openModal = this.openModal.bind(this);

    this.state = {
      overlayDialog: false,
    }
  }

  addClass = (item, name) => {
    return item.classList.add(name);
  }

  removeClass = (item, name) => {
    return item.classList.remove(name);
  }

  removeAllClass = (item) => {
    return item.className = '';
  }

  openModal(id) {
    this.addClass(document.querySelector('body'), 'hidden');
    this.setState({overlayDialog: true});
    this.addClass(document.querySelector('#' + id), 'open');
  }

  closeModal(id) {
    this.removeClass(document.querySelector('body'), 'hidden');
    this.setState({overlayDialog: false});
    this.removeClass(document.querySelector('#' + id), 'open');
  }

  componentWillUnmount() {
    this.removeAllClass(document.querySelector('body'));
  }

  render() {

    const services = this.props.data;
    const intl = this.props.pageContext.intl;

    return (
      <Layout>
        <SEO lang={intl.language} title={intl.messages.servicesSeoTitle} description={intl.messages.servicesSeoDescription} />

        <div className="services">
          <div className="container">
            <div className="services-col">
              <div className="services-item services-item-1">
                <div className="services-item-image"></div>
                <h1 className="services-item-title"><FormattedMessage id="servicesItemTitle1" /></h1>
                <div className="services-item-text"><FormattedMessage id="servicesItemText1" /></div>
                <div className="services-item-meta">
                  <div className="services-item-meta-price" dangerouslySetInnerHTML={{ __html: intl.messages.servicesItemPrice1}} />
                  <button type="button" className="link services-item-meta-link" onClick={() => this.openModal('services-1')}><FormattedMessage id="servicesItemButtonMore" /> <i className="arrow--right arrow--right-light"></i></button>
                </div>
              </div>
            </div>
            <div className="services-col">
              <div className="services-item services-item-2">
                <div className="services-item-image"></div>
                <h2 className="services-item-title"><FormattedMessage id="servicesItemTitle2" /></h2>
                <div className="services-item-text"><FormattedMessage id="servicesItemText2" /></div>
                <div className="services-item-meta">
                  <div className="services-item-meta-price" dangerouslySetInnerHTML={{ __html: intl.messages.servicesItemPrice2}} />
                  <button type="button" className="link services-item-meta-link" onClick={() => this.openModal('services-2')}><FormattedMessage id="servicesItemButtonMore" /> <i className="arrow--right arrow--right-light"></i></button>
                </div>
              </div>
            </div>
            <div className="services-col">
              <div className="services-item services-item-3">
                <div className="services-item-image"></div>
                <h2 className="services-item-title"><FormattedMessage id="servicesItemTitle3" /></h2>
                <div className="services-item-text"><FormattedMessage id="servicesItemText3" /></div>
                <div className="services-item-meta">
                  <div className="services-item-meta-price" dangerouslySetInnerHTML={{ __html: intl.messages.servicesItemPrice3}} />
                  <button type="button" className="link services-item-meta-link" onClick={() => this.openModal('services-3')}><FormattedMessage id="servicesItemButtonMore" /> <i className="arrow--right arrow--right-light"></i></button>
                </div>
              </div>
            </div>
            <div className="services-col">
              <div className="services-item services-item-4">
                <div className="services-item-image"></div>
                <h2 className="services-item-title"><FormattedMessage id="servicesItemTitle4" /></h2>
                <div className="services-item-text"><FormattedMessage id="servicesItemText4" /></div>
                <div className="services-item-meta">
                  <div className="services-item-meta-price" dangerouslySetInnerHTML={{ __html: intl.messages.servicesItemPrice4}} />
                  <button type="button" className="link services-item-meta-link" onClick={() => this.openModal('services-4')}><FormattedMessage id="servicesItemButtonMore" /> <i className="arrow--right arrow--right-light"></i></button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className={(this.state.overlayDialog?'services-dialog-overlay show':'services-dialog-overlay')}>
          <div id="services-1" className="services-dialog">
            <div className="services-dialog-header">
              <button type="button" className="services-dialog-header-close" onClick={() => this.closeModal('services-1')}></button>
              <div className="services-dialog-header-title"><FormattedMessage id="servicesItemTitle1" /></div>
              <div className="services-dialog-header-text"><FormattedMessage id="servicesItemText1" /></div>
            </div>
            <div className="services-dialog-body">

              <div className="services-dialog-linework">
                <ol>
                  <li>
                    <div className="services-dialog-linework-title"><FormattedMessage id="servicesLineworkTitle1" /></div>
                    <div className="services-dialog-linework-text"><FormattedMessage id="servicesLineworkText1" /></div>
                  </li>
                  <li>
                    <div className="services-dialog-linework-title"><FormattedMessage id="servicesLineworkTitle2" /></div>
                  </li>
                  <li>
                    <div className="services-dialog-linework-title"><FormattedMessage id="servicesLineworkTitle3" /></div>
                    <div className="services-dialog-linework-text"><FormattedMessage id="servicesLineworkText3" /></div>
                  </li>
                  <li>
                    <div className="services-dialog-linework-title"><FormattedMessage id="servicesLineworkTitle4" /></div>
                    <div className="services-dialog-linework-text"><FormattedMessage id="servicesLineworkText4" /></div>
                  </li>
                  <li>
                    <div className="services-dialog-linework-title"><FormattedMessage id="servicesLineworkTitle5" /></div>
                    <div className="services-dialog-linework-text"><FormattedMessage id="servicesLineworkText5" /></div>
                  </li>
                  <li>
                    <div className="services-dialog-linework-title"><FormattedMessage id="servicesLineworkTitle6" /></div>
                    <div className="services-dialog-linework-text"><FormattedMessage id="servicesLineworkText6" /></div>
                  </li>
                  <li>
                    <div className="services-dialog-linework-title"><FormattedMessage id="servicesLineworkTitle7" /></div>
                    <div className="services-dialog-linework-text"><FormattedMessage id="servicesLineworkText7" /></div>
                  </li>
                  <li>
                    <div className="services-dialog-linework-title"><FormattedMessage id="servicesLineworkTitle8" /></div>
                    <div className="services-dialog-linework-text"><FormattedMessage id="servicesLineworkText8" /></div>
                  </li>
                  <li>
                    <div className="services-dialog-linework-title"><FormattedMessage id="servicesLineworkTitle9" /></div>
                    <div className="services-dialog-linework-text"><FormattedMessage id="servicesLineworkText9" /></div>
                  </li>
                </ol>
              </div>

            </div>
            <div className="services-dialog-footer">
                <div className="services-dialog-footer-price" dangerouslySetInnerHTML={{ __html: intl.messages.servicesItemPrice1}} />
                <div className="services-dialog-footer-buttons">
                  <a className="button button--unfull" href={services.allFile.edges[0].node.publicURL} target="_blank" rel="noopener noreferrer"><FormattedMessage id="servicesItemButtonView" /></a>
                  <Link to="/#home-page-5" className="button button--full services-dialog-footer-link"><FormattedMessage id="servicesItemButtonBuy" /> <i className="arrow--right"></i></Link>
                </div>
            </div>
          </div>

          <div id="services-2" className="services-dialog">
            <div className="services-dialog-header">
              <button type="button" className="services-dialog-header-close" onClick={() => this.closeModal('services-2')}></button>
              <div className="services-dialog-header-title"><FormattedMessage id="servicesItemTitle2" /></div>
              <div className="services-dialog-header-text"><FormattedMessage id="servicesItemText2" /></div>
            </div>
            <div className="services-dialog-body">

              <ul className="services-dialog-list">
                <li>
                    <div className="services-dialog-list-title"><FormattedMessage id="servicesFeatureTitle1" /></div>
                    <div className="services-dialog-list-text"><FormattedMessage id="servicesFeatureText1" /></div>
                </li>
                <li>
                    <div className="services-dialog-list-title"><FormattedMessage id="servicesFeatureTitle2" /></div>
                    <div className="services-dialog-list-text"><FormattedMessage id="servicesFeatureText2" /></div>
                </li>
              </ul>

            </div>
            <div className="services-dialog-footer">
                <div className="services-dialog-footer-price" dangerouslySetInnerHTML={{ __html: intl.messages.servicesItemPrice2}} />
                <Link to="/#home-page-5" className="button button--full services-dialog-footer-link"><FormattedMessage id="servicesItemButtonBuy" /> <i className="arrow--right"></i></Link>
            </div>
          </div>

          <div id="services-3" className="services-dialog">
            <div className="services-dialog-header">
              <button type="button" className="services-dialog-header-close" onClick={() => this.closeModal('services-3')}></button>
              <div className="services-dialog-header-title"><FormattedMessage id="servicesItemTitle3" /></div>
              <div className="services-dialog-header-text"><FormattedMessage id="servicesItemText3" /></div>
            </div>
            <div className="services-dialog-body">

              <div className="services-dialog-equipment">
                <ul>
                  <li>
                    <div className="services-dialog-equipment-title"><FormattedMessage id="servicesEquipmentTitle1" /></div>
                    <div className="services-dialog-equipment-text"><FormattedMessage id="servicesEquipmentText1" /></div>
                    <div className="services-dialog-equipment-price" dangerouslySetInnerHTML={{ __html: intl.messages.servicesEquipmentPrice1}} />
                  </li>
                  <li>
                    <div className="services-dialog-equipment-title"><FormattedMessage id="servicesEquipmentTitle2" /></div>
                    <div className="services-dialog-equipment-text"><FormattedMessage id="servicesEquipmentText2" /></div>

                    <ul className="services-dialog-list">
                      <li>
                          <div className="services-dialog-list-title"><FormattedMessage id="servicesEquipment2ListTitle1" /></div>
                          <div className="services-dialog-list-text"><FormattedMessage id="servicesEquipment2ListText1" /></div>
                      </li>
                      <li>
                          <div className="services-dialog-list-title"><FormattedMessage id="servicesEquipment2ListTitle2" /></div>
                          <div className="services-dialog-list-text"><FormattedMessage id="servicesEquipment2ListText2" /></div>
                      </li>
                      <li>
                          <div className="services-dialog-list-title"><FormattedMessage id="servicesEquipment2ListTitle3" /></div>
                          <div className="services-dialog-list-text"><FormattedMessage id="servicesEquipment2ListText3" /></div>
                      </li>
                      <li>
                          <div className="services-dialog-list-title"><FormattedMessage id="servicesEquipment2ListTitle4" /></div>
                          <div className="services-dialog-list-text"><FormattedMessage id="servicesEquipment2ListText4" /></div>
                      </li>
                      <li>
                          <div className="services-dialog-list-title"><FormattedMessage id="servicesEquipment2ListTitle5" /></div>
                          <div className="services-dialog-list-text"><FormattedMessage id="servicesEquipment2ListText5" /></div>
                      </li>
                    </ul>
                    <div className="services-dialog-equipment-price" dangerouslySetInnerHTML={{ __html: intl.messages.servicesEquipmentPrice2}} />
                  </li>
                  <li>
                    <div className="services-dialog-equipment-title"><FormattedMessage id="servicesEquipmentTitle3" /></div>
                    <div className="services-dialog-equipment-text"><FormattedMessage id="servicesEquipmentText3" /></div>

                    <ul className="services-dialog-list">
                      <li>
                          <div className="services-dialog-list-title"><FormattedMessage id="servicesEquipment3ListTitle1" /></div>
                          <div className="services-dialog-list-text"><FormattedMessage id="servicesEquipment3ListText1" /></div>
                      </li>
                      <li>
                          <div className="services-dialog-list-title"><FormattedMessage id="servicesEquipment3ListTitle2" /></div>
                          <div className="services-dialog-list-text"><FormattedMessage id="servicesEquipment3ListText2" /></div>
                      </li>
                     <li>
                          <div className="services-dialog-list-title"><FormattedMessage id="servicesEquipment3ListTitle3" /></div>
                          <div className="services-dialog-list-text"><FormattedMessage id="servicesEquipment3ListText3" /></div>
                      </li>
                      <li>
                          <div className="services-dialog-list-title"><FormattedMessage id="servicesEquipment3ListTitle4" /></div>
                          <div className="services-dialog-list-text"><FormattedMessage id="servicesEquipment3ListText4" /></div>
                      </li>
                    </ul>
                    <div className="services-dialog-equipment-price" dangerouslySetInnerHTML={{ __html: intl.messages.servicesEquipmentPrice3}} />
                  </li>
                </ul>
              </div>

            </div>
            <div className="services-dialog-footer">
                <div className="services-dialog-footer-price" dangerouslySetInnerHTML={{ __html: intl.messages.servicesItemPrice3}} />
                <Link to="/#home-page-5" className="button button--full services-dialog-footer-link"><FormattedMessage id="servicesItemButtonBuy" /> <i className="arrow--right"></i></Link>
            </div>
          </div>

          <div id="services-4" className="services-dialog">
            <div className="services-dialog-header">
              <button type="button" className="services-dialog-header-close" onClick={() => this.closeModal('services-4')}></button>
              <div className="services-dialog-header-title"><FormattedMessage id="servicesItemTitle4" /></div>
            </div>
            <div className="services-dialog-body">

              <ul className="services-dialog-list">
                <li>
                    <div className="services-dialog-list-title"><FormattedMessage id="servicesList4Title1" /></div>
                </li>
                <li>
                    <div className="services-dialog-list-title"><FormattedMessage id="servicesList4Title2" /></div>
                </li>
                <li>
                    <div className="services-dialog-list-title"><FormattedMessage id="servicesList4Title3" /></div>
                </li>
                <li>
                    <div className="services-dialog-list-title"><FormattedMessage id="servicesList4Title4" /></div>
                </li>
              </ul>

            </div>
            <div className="services-dialog-footer">
                <div className="services-dialog-footer-price" dangerouslySetInnerHTML={{ __html: intl.messages.servicesItemPrice4}} />
                <Link to="/#home-page-5" className="button button--full services-dialog-footer-link"><FormattedMessage id="servicesItemButtonBuy" /> <i className="arrow--right"></i></Link>
            </div>
          </div>
        </div>

      </Layout>
    )
  }
}

export default injectIntl(ServicePage)

export const pageQuery = graphql`
  query {
    allFile(filter: { relativePath: { eq: "example.pdf" } }) {
      edges {
        node {
          publicURL
          name
        }
      }
    }
  }
`